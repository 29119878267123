import React, {useState} from "react";
import {H2, TabControl, TextL} from "@mryaros/mryaros-design-system";
import {TabHeaderComponentProps} from "@mryaros/mryaros-design-system/dist/lib/components/controls/Tab/TabHeaderItem";

enum TabId {
    LINEAR = "LINEAR",
    QUADRATIC = "QUADRATIC",
    CUBIC = "CUBIC",
}

export const PageTheory = React.memo(function PageTheory() {
    const [activeTab, setActiveTab] = useState(TabId.LINEAR);

    return (
        <>
            <H2>Сводка теории по типам рассматриваемых задач</H2>
            <TabControl  selectedTabId={activeTab} onTabChange={(tab) => setActiveTab(tab as TabId)}>
                <LinearTab tabId={TabId.LINEAR} tabName="Линейная функция" />
                <SquareTab tabId={TabId.QUADRATIC} tabName="Квадратичная функция" />
                <CubicTab tabId={TabId.CUBIC} tabName="Кубическая функция" />
            </TabControl>
        </>
    );
})

// eslint-disable-next-line no-empty-pattern
function LinearTab({}: TabHeaderComponentProps) {
    return (
        <TextL className="margin-t-16">
            Линейная функция — это функция вида y = K*x + B, где х — независимая переменная, K, B — некоторые числа. При этом K — угловой коэффициент, B — свободный коэффициент. Геометрический смысл коэффициента B — длина отрезка, который отсекает прямая по оси OY, считая от начала координат.
        </TextL>
    );
}

// eslint-disable-next-line no-empty-pattern
function SquareTab({}: TabHeaderComponentProps) {
    return (
        <TextL className="margin-t-16">
            Квадратичная функция задается формулой y = A*x*x + B*x + C, где x и y — переменные, A, B, C — заданные числа, обязательное условие — A ≠ 0.
            <br/>График квадратичной функции — парабола.
        </TextL>
    );
}

// eslint-disable-next-line no-empty-pattern
function CubicTab({}: TabHeaderComponentProps) {
    return (
        <TextL className="margin-t-16">
            Кубическая функция задается формулой y = A*x*x*x + B*x*x + C*x + D, где x и y — переменные, A, B, C, D — заданные числа, обязательное условие — A ≠ 0.
            <br/>График кубической функции — кубическая парабола.
        </TextL>
    );
}