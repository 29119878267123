import React from 'react';
import '../App.scss';
import SessionProvider from "./core/context/SessionContext";
import {BrowserRouter} from "react-router-dom";
import {Background, ModalProvider} from "@mryaros/mryaros-design-system";
import {ApplicationHeader} from "./components/ApplicationHeader";
import {ApplicationFooter} from "./components/ApplicationFooter";
import {Page} from "./pages/page/Page";
import {ScrollToTop} from "./core/CustomReactHooks";

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <SessionProvider>
                <ModalProvider>
                    <Background/>
                    <div className="app">
                        <ApplicationHeader/>
                        <Page/>
                        <ApplicationFooter/>
                    </div>
                </ModalProvider>
            </SessionProvider>
        </BrowserRouter>
    )
}

export default App;
