import {TokenDto} from "./response/TokenDto";
import {AuthSettingDto} from "./response/AuthSettingDto";
import IResponseBody from "./response/IResponseBody";

export enum RequestEndpoint {
    logout = "auth/logout",
    getRefreshToken = "auth/refreshtoken",
    getAuthSettings = "settings/auth",
    getTokenDto = "auth/tokenDto",
}

type ResponseTypes = {
    [RequestEndpoint.getRefreshToken]: TokenDto,
    [RequestEndpoint.logout]: IResponseBody,
    [RequestEndpoint.getTokenDto]: TokenDto,
    [RequestEndpoint.getAuthSettings]: AuthSettingDto,
};

export type RequestInfoTypeMapping = {[key in RequestEndpoint]: IResponseBody} & ResponseTypes