import React from "react";
import {H1, H4, TextL} from "@mryaros/mryaros-design-system";

export const PageMain = React.memo(function PageMain() {
    return (
        <>
            <H1>О типовом проекте</H1>
            <TextL>
                Это пример типового проекта. Он состоит из трех основных блоков: элементы теории, примеры решения задач и страницу с возможностью решения задачи на основе параметров, введенных пользователем.
                <br/> В качестве примера рассмотрена задача построения графиков линейной, квадратичной и кубической функций.
                <br/> У типового проекта есть возможность взаимодействовать с платформой, в частности, все пользователи, которые зарегистрированы на платформе, могут им воспользоваться.
                <br/> Типовой проект доступен по запросу и у каждого есть возможность реализовать свой проект на его основе.
                <H4 className="margin-t-8">У каждого есть возможность, реализовать свой проект и подключить его к платформе!</H4>
                Если у Вы создали эконометрическую модель, реализовали уникальный метод классификации или просто разработали уникальный алгоритм, который будет решать какую-либо задачу,
                и у Вас есть желание им поделиться, то вам просто необходимо реализовать свой проект и подключить его к платформе!
                <br/>Ниже можно ознакомиться с информацией о том, как получить доступ к типовому проекту и найти ссылки на учебные материалы, которые желательно знать, для успешной разработки.
            </TextL>
            <H1>С чего начать?</H1>
            <ul>
                <li>
                    <TextL>
                        Запросить доступы к&nbsp;
                        <a href="http://gitlab.mryaros.ru/" target="_blank" rel="noreferrer">gitlab</a>,&nbsp;
                        <a href="https://nexus.mryaros.ru/" target="_blank" rel="noreferrer">nexus</a>,&nbsp;
                        <a href="https://mryaros.atlassian.net/wiki/home" target="_blank" rel="noreferrer">документации</a>&nbsp;
                        написав письмо на почту mr.yaros@yandex.ru
                    </TextL>
                </li>
                <li>
                    <TextL>
                        Изучить <a href="https://mryaros.atlassian.net/wiki/spaces/PS/pages/6881281" target="_blank" rel="noreferrer">набор технологий</a>, которые использовались при реализации типового проекта
                    </TextL>
                </li>
                <li>
                    <TextL>
                        Скачать <a href="http://gitlab.mryaros.ru/default_project/default_project.git" target="_blank" rel="noreferrer">серверную</a> и&nbsp;
                        <a href="http://gitlab.mryaros.ru/default_project/default_project_front.git" target="_blank" rel="noreferrer">клиентскую</a> часть типового проекта
                    </TextL>
                </li>
                <li>
                    <TextL>
                        Реализовать свой уникальный алгоритм на основе типового проекта
                    </TextL>
                </li>
                <li>
                    <TextL>
                        Зарегистрировать проект на платформе написав письмо на почту mr.yaros@yandex.ru
                    </TextL>
                </li>
                <li>
                    <TextL>
                        Запустить свой проект
                    </TextL>
                </li>
            </ul>

            <H1>Требования к приложению</H1>
            <ul>
                <li><TextL>В приложении должны поддерживаться запросы, которые обрабатываются платформой. Для этого реализована библиотека, которая хранится в nexus, и типовой проект с использованием этой библиотеки</TextL></li>
                <li><TextL>Язык программирования и прочие технологии не регламентируются</TextL></li>
                <li><TextL>Разработанное web-приложение должно содержать 3 обязательные части: теория с описанием алгоритма, пример взаимодействия с приложением и основную часть с возможностью практического применения реализованного алгоритма</TextL></li>
                <li><TextL>Предлагается использовать компоненты из разработанной дизайн-системы, которая хранится в виде npm-пакета в nexus</TextL></li>
            </ul>
        </>
    );
});