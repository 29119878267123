import {Route, Routes} from "react-router-dom";
import "./page.scss";
import {PageMain} from "../page_main/PageMain";
import {PageTheory} from "../page_theory/PageTheory";
import {PageExample} from "../page_example/PageExample";
import {PageResolveTask} from "../page_resolve_task/PageResolveTask";

export function Page() {
    return (
        <div className="page">
            <Routes>
                <Route path="/theory" element={<PageTheory/>}/>
                <Route path="/example" element={<PageExample/>}/>
                <Route path="/resolveTask" element={<PageResolveTask/>}/>
                <Route path="*" element={<PageMain/>}/>
            </Routes>
        </div>
    )
}