import React, {useContext} from "react";
import {Button, EButtonStyle, TypicalHeader} from "@mryaros/mryaros-design-system";
import MrYarosLogo from "@mryaros/mryaros-design-system/src/lib/core/TypicalHeader/MrYarosLogo.png";
import {Link} from "react-router-dom";
import {SessionContext} from "../core/context/SessionContext";

export const ApplicationHeader = React.memo(function ApplicationHeader() {
    const {logout} = useContext(SessionContext);

    const headerLinks = (<>
        <Link to="/">ГЛАВНАЯ</Link>
        <Link to="/theory" className="link-block">ЭЛЕМЕНТЫ ТЕОРИИ</Link>
        <Link to="/example" className="link-block">ПРИМЕР РЕШЕНИЯ ЗАДАЧИ</Link>
        <Link to="/resolveTask" className="link-block">РЕШИТЬ СВОЮ ЗАДАЧУ</Link>
    </>);

    const additionalBlock = (
        <Button label="На платформу" buttonStyle={EButtonStyle.SECONDARY} onClick={() => logout()} />
    );
    return <TypicalHeader logoSrc={MrYarosLogo} headerLinks={headerLinks} additionalBlock={additionalBlock} />
})