import axios, {AxiosError, AxiosResponse} from "axios";
import IResponseBody from "./response/IResponseBody";
import {RequestEndpoint, RequestInfoTypeMapping} from "./RequestEndpoint";
import ErrorResponse from "./response/ErrorResponse";

export enum ERequestMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}

export type RequestOptions = {
    needAuth: boolean
}

const DEFAULT_REQUEST_OPTION = {
    needAuth: true,
}

//TODO вынести на уровень SessionContext ?
export default class RequestExecutor {
    /** @deprecated использовать SessionContext.callWithTokenCheck */
    static call<K extends RequestEndpoint, RESP extends IResponseBody = RequestInfoTypeMapping[K]>(
        endpoint: K,
        method: ERequestMethod,
        requestBody: object = {},
        queryParam: object = {},
        options: Partial<RequestOptions> = {},
    ): Promise<RESP> {
        const url = process.env.REACT_APP_SERVER_URL;
        options = {...DEFAULT_REQUEST_OPTION, ...options};

        const config = {
            headers: {},
        };
        if (options.needAuth) {
            config["headers"] = {
                "Authorization": `Bearer ${window.localStorage.getItem("Authorization")}` || "",
            }
        }

        let queryString = "";
        for (let key in queryParam) {
            // @ts-ignore
            queryString += `&${key}=${queryParam[key]}`
        }

        if (queryString) {
            queryString = queryString.replace("&", "?")
        }

        let promise: Promise<AxiosResponse<IResponseBody>>;
        switch (method) {
            case ERequestMethod.GET:
                promise = axios.get(`${url}${endpoint}${queryString}`, config);
                break;
            case ERequestMethod.POST:
                promise = axios.post(`${url}${endpoint}${queryString}`, requestBody, config);
                break;
            case ERequestMethod.PUT:
                promise = axios.put(`${url}${endpoint}${queryString}`, requestBody, config);
                break;
            case ERequestMethod.DELETE:
                promise = axios.delete(`${url}${endpoint}${queryString}`, config);
                break;
        }
        //TODO добавить обработку http статусов
        return promise
            .then((axiosResponse: AxiosResponse<IResponseBody>): Promise<RESP> => {
                return Promise.resolve(axiosResponse.data as RESP);
            })
            .catch((axiosResponse: AxiosError<ErrorResponse>): Promise<RESP> => {
                return Promise.reject((axiosResponse.response?.data || {errorDescription: "Произошла ошибка"}) as ErrorResponse);
            });
    }

    static callNotAuth<K extends RequestEndpoint, RESP extends IResponseBody = RequestInfoTypeMapping[K]>(
        endpoint: K,
        method: ERequestMethod,
        requestBody: object = {},
        queryParam: object = {},
        options: Partial<RequestOptions> = {},
    ): Promise<RESP> {
        return this.call(endpoint, method, requestBody, queryParam, {...options, needAuth: false})
    }
}