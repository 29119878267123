import {H4, TextM, TypicalFooter} from "@mryaros/mryaros-design-system";
import {Link} from "react-router-dom";
import React from "react";

export const ApplicationFooter = React.memo(function ApplicationFooter() {
    const mainBlocks = (
        <>
            <div>
                <TextM className="margin-no">Разработчик</TextM>
                <H4 className="margin-no">Кузнецов Ярослав Андреевич</H4>
                <TextM className="margin-no">Контактные данные</TextM>
                <H4 className="margin-no">mr.yaros@yandex.ru</H4>
            </div>
            <div>
                <TextM className="margin-no">Научный руководитель</TextM>
                <H4 className="margin-no">Максимов Владимир Петрович</H4>
            </div>
            <div className="flex-col">
                <Link to="/about">О типовом проекте</Link>
                <Link to="/theory" className="link-block">Элементы теории</Link>
                <Link to="/example" className="link-block">Пример решения задачи</Link>
                <Link to="/resolveTask" className="link-block">Решить свою задачу</Link>
            </div>
            <div className="flex-col">
                <a href="https://design.mryaros.ru" target="_blank" rel="noreferrer">Дизайн-система</a>
                <a href="https://mryaros.ru" target="_blank" rel="noreferrer">О создателе</a>
            </div>
        </>
    );
    return (
        <TypicalFooter mainBlocks={mainBlocks} className="margin-t-64" />
    );
});