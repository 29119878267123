import React, {useState} from "react";
import {H2, Image, TabControl, TextL} from "@mryaros/mryaros-design-system";
import {TabHeaderComponentProps} from "@mryaros/mryaros-design-system/dist/lib/components/controls/Tab/TabHeaderItem";
import {Link} from "react-router-dom";
import LinearParamExample from "../../../static/LinearParamExample.png";
import LinearResultExample from "../../../static/LinearResultExample.png";
import SquareParamExample from "../../../static/SquareParamExample.png";
import SquareResultExample from "../../../static/SquareResultExample.png";
import CubicParamExample from "../../../static/CubicParamExample.png";
import CubicResultExample from "../../../static/CubicResultExample.png";

enum TabId {
    LINEAR = "LINEAR",
    QUADRATIC = "QUADRATIC",
    CUBIC = "CUBIC",
}

export const PageExample = React.memo(function PageExample() {
    const [activeTab, setActiveTab] = useState(TabId.LINEAR);

    return (
        <>
            <H2>Примеры решения задач</H2>
            <TabControl  selectedTabId={activeTab} onTabChange={(tab) => setActiveTab(tab as TabId)}>
                <LinearTab tabId={TabId.LINEAR} tabName="Линейная функция" />
                <SquareTab tabId={TabId.QUADRATIC} tabName="Квадратичная функция" />
                <CubicTab tabId={TabId.CUBIC} tabName="Кубическая функция" />
            </TabControl>
        </>
    );
})

// eslint-disable-next-line no-empty-pattern
function LinearTab({}: TabHeaderComponentProps) {
    return (
        <>
            <TextL className="margin-t-24">
                Перед решением задачи предлагается ознакомится с выжимками <Link to="/theory">теории</Link> для данного типа задачи.
                Для построения графика линейной функции необходимо заполнить необходимые параметры.
            </TextL>
            <Image src={LinearParamExample} alt="LinearParamExample" name="Рисунок 1. Пример введения параметров" />
            <TextL>
                После чего ниже появится график функции, который соответствует введенным параметрам.
            </TextL>
            <Image src={LinearResultExample} alt="LinearResultExample" name="Рисунок 2. Результат построения графика с введенными параметрами" />
            <TextL>
                Задачу построения графика линейной функции можно решить на странице <Link to="/resolveTask">РЕШИТЬ СВОЮ ЗАДАЧУ</Link>.
            </TextL>
        </>
    );
}

// eslint-disable-next-line no-empty-pattern
function SquareTab({}: TabHeaderComponentProps) {
    return (
        <>
            <TextL className="margin-t-24">
                Перед решением задачи предлагается ознакомится с выжимками <Link to="/theory">теории</Link> для данного типа задачи.
                Для построения графика квадратичной функции необходимо заполнить необходимые параметры.
            </TextL>
            <Image src={SquareParamExample} alt="SquareParamExample" name="Рисунок 1. Пример введения параметров" />
            <TextL>
                После чего ниже появится график функции, который соответствует введенным параметрам.
            </TextL>
            <Image src={SquareResultExample} alt="SquareResultExample" name="Рисунок 2. Результат построения графика с введенными параметрами" />
            <TextL>
                Задачу построения графика квадратичной функции можно решить на странице <Link to="/resolveTask">РЕШИТЬ СВОЮ ЗАДАЧУ</Link>.
            </TextL>
        </>
    );
}

// eslint-disable-next-line no-empty-pattern
function CubicTab({}: TabHeaderComponentProps) {
    return (
        <>
            <TextL className="margin-t-24">
                Перед решением задачи предлагается ознакомится с выжимками <Link to="/theory">теории</Link> для данного типа задачи.
                Для построения графика кубической функции необходимо заполнить необходимые параметры.
            </TextL>
            <Image src={CubicParamExample} alt="CubicParamExample" name="Рисунок 1. Пример введения параметров" />
            <TextL>
                После чего ниже появится график функции, который соответствует введенным параметрам.
            </TextL>
            <Image src={CubicResultExample} alt="CubicResultExample" name="Рисунок 2. Результат построения графика с введенными параметрами" />
            <TextL>
                Задачу построения графика кубической функции можно решить на странице <Link to="/resolveTask">РЕШИТЬ СВОЮ ЗАДАЧУ</Link>.
            </TextL>
        </>
    );
}