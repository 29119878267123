import React, {useEffect, useState} from "react";
import {LineNumberChartData, H2, H4, LineChart, Section, TabControl, TextField, TextS} from "@mryaros/mryaros-design-system";
import {TabHeaderComponentProps} from "@mryaros/mryaros-design-system/dist/lib/components/controls/Tab/TabHeaderItem";

enum TabId {
    LINEAR = "LINEAR",
    QUADRATIC = "QUADRATIC",
    CUBIC = "CUBIC",
}

export const PageResolveTask = React.memo(function PageResolveTask() {
    const [activeTab, setActiveTab] = useState(TabId.LINEAR);

    return (
        <>
            <H2>Построение графиков</H2>
            <TabControl  selectedTabId={activeTab} onTabChange={(tab: string) => setActiveTab(tab as TabId)}>
                <LinearTab tabId={TabId.LINEAR} tabName="Линейная функция" />
                <SquareTab tabId={TabId.QUADRATIC} tabName="Квадратичная функция" />
                <CubicTab tabId={TabId.CUBIC} tabName="Кубическая функция" />
            </TabControl>
        </>
    );
})

// eslint-disable-next-line no-empty-pattern
function LinearTab({}: TabHeaderComponentProps) {
    const [update, setUpdate] = useState<boolean>();
    const [k, setK] = useState<string>();
    const [b, setB] = useState<string>();
    const [data, setData] = useState<Array<LineNumberChartData>>();

    useEffect(() => {
        setUpdate(false);
        if (!k || !b) {
            return;
        }

        const _data = new Array(10);
        for (let i = 0; i < 10; i++) {
            let y = +k * i + +b;
            _data[i] = [i, y];
        }
        setData(_data);
    }, [k, b]);

    useEffect(() => {
        setUpdate(true);
    }, [data])

    return (
        <>
            <Section className="padding-text margin-t-24">
                <H4 className="margin-no">Введите параметры линейной функции</H4>
                <TextS>Линейная функция имеет вид y=K*x+B</TextS>

                <div className="flex-row flex-align-center margin-b-16">
                    <TextS className="margin-b-8 margin-r-8">K = </TextS>
                    <TextField onChange={(k: string) => setK(k)} />
                </div>
                <div className="flex-row flex-align-center">
                    <TextS className="margin-b-8 margin-r-8">B = </TextS>
                    <TextField onChange={(b: string) => setB(b)} />
                </div>
            </Section>
            {update && data && (<Section className="padding-medium margin-t-24">
                <LineChart header = "Линейная функция" description={`y=${k}x+${b}`} data={data} />
            </Section>)}
        </>
    );
}

// eslint-disable-next-line no-empty-pattern
function SquareTab({}: TabHeaderComponentProps) {
    const [update, setUpdate] = useState<boolean>();
    const [a, setA] = useState<string>();
    const [b, setB] = useState<string>();
    const [c, setC] = useState<string>();
    const [data, setData] = useState<Array<LineNumberChartData>>();

    useEffect(() => {
        setUpdate(false);
        if (!a || !b || !c) {
            return;
        }

        const _data = new Array(10);
        for (let i = 0; i < 10; i++) {
            let y = +a * i * i + +b * i + +c;
            _data[i] = [i, y];
        }
        setData(_data);
    }, [a, b, c]);

    useEffect(() => {
        setUpdate(true);
    }, [data])

    return (
        <>
            <Section className="padding-text margin-t-24">
                <H4 className="margin-no">Введите параметры квадратичной функции</H4>
                <TextS>Квадратичная функция имеет вид y=A*x*x+B*x+C</TextS>

                <div className="flex-row flex-align-center margin-b-16">
                    <TextS className="margin-b-8 margin-r-8">A = </TextS>
                    <TextField onChange={(a: string) => setA(a)} />
                </div>
                <div className="flex-row flex-align-center margin-b-16">
                    <TextS className="margin-b-8 margin-r-8">B = </TextS>
                    <TextField onChange={(b: string) => setB(b)} />
                </div>
                <div className="flex-row flex-align-center">
                    <TextS className="margin-b-8 margin-r-8">C = </TextS>
                    <TextField onChange={(c: string) => setC(c)} />
                </div>
            </Section>
            {update && data && (<Section className="padding-medium margin-t-24">
                <LineChart header = "Квадратичная функция" description={`y=${a}*x*x+${b}*x+${c}`} data={data} curve />
            </Section>)}
        </>
    );
}

// eslint-disable-next-line no-empty-pattern
function CubicTab({}: TabHeaderComponentProps) {
    const [update, setUpdate] = useState<boolean>();
    const [a, setA] = useState<string>();
    const [b, setB] = useState<string>();
    const [c, setC] = useState<string>();
    const [d, setD] = useState<string>();
    const [data, setData] = useState<Array<LineNumberChartData>>();

    useEffect(() => {
        setUpdate(false);
        if (!a || !b || !c || !d) {
            return;
        }

        const _data = new Array(10);
        for (let i = 0; i < 10; i++) {
            let y = +a * i * i *i + +b * i *i + +c * i + +d;
            _data[i] = [i, y];
        }
        setData(_data);
    }, [a, b, c, d]);

    useEffect(() => {
        setUpdate(true);
    }, [data])

    return (
        <>
            <Section className="padding-text margin-t-24">
                <H4 className="margin-no">Введите параметры кубической функции</H4>
                <TextS>Квадратичная функция имеет вид y=A*x*x*x+B*x*x+C*x+D</TextS>

                <div className="flex-row flex-align-center margin-b-16">
                    <TextS className="margin-b-8 margin-r-8">A = </TextS>
                    <TextField onChange={(a: string) => setA(a)} />
                </div>
                <div className="flex-row flex-align-center margin-b-16">
                    <TextS className="margin-b-8 margin-r-8">B = </TextS>
                    <TextField onChange={(b: string) => setB(b)} />
                </div>
                <div className="flex-row flex-align-center margin-b-16">
                    <TextS className="margin-b-8 margin-r-8">C = </TextS>
                    <TextField onChange={(c: string) => setC(c)} />
                </div>
                <div className="flex-row flex-align-center ">
                    <TextS className="margin-b-8 margin-r-8">D = </TextS>
                    <TextField onChange={(d: string) => setD(d)} />
                </div>
            </Section>
            {update && data && (<Section className="padding-medium margin-t-24">
                <LineChart header = "Кубическая функция" description={`y=${a}*x*x*x+${b}*x*x+${c}*x+${d}`} data={data} curve />
            </Section>)}
        </>
    );
}