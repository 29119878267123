import * as React from "react";
import {HTMLAttributes, useCallback, useState} from "react";
import {SessionContextType} from "./@types/SessionContext";
import RequestExecutor, {ERequestMethod, RequestOptions} from "../RequestExecutor";
import {AuthSettingDto} from "../response/AuthSettingDto";
import {useEffectAsync} from "../CustomReactHooks";
import {TokenDto} from "../response/TokenDto";
import {RequestEndpoint, RequestInfoTypeMapping} from "../RequestEndpoint";
import {getQueryParam} from "../Utils";
import IResponseBody from "../response/IResponseBody";
import {useNavigate} from "react-router-dom";

const SessionContextDefault = {
    tokenDto: null,
    callWithTokenCheck: () => Promise.resolve({} as IResponseBody),
    logout: () => {},
};

// @ts-ignore
export const SessionContext = React.createContext<SessionContextType>(SessionContextDefault);

const SessionProvider: React.FC<HTMLAttributes<HTMLElement>> = ( {children}: HTMLAttributes<HTMLElement> ) => {
    const [tokenDto, setTokenDto] = useState<TokenDto | null>(null);
    const [authSettings, setAuthSettings] = useState<AuthSettingDto | null>(null);
    const [initLoading, setInitLoading] = useState<boolean>(true);

    const redirectToSsoAuth = useCallback(() => {
        if (!authSettings) {
            return;
        }
        window.location.href = `${authSettings?.ssoAuthUrl}?redirect_uri=${authSettings?.redirectUri}&response_type=code&client_id=${authSettings?.clientId}&scope=ALL&state=true`
    }, [authSettings]);

    const redirectToSso = useCallback(() => {
        if (!authSettings) {
            return;
        }
        window.location.href = `${authSettings?.ssoUrl}`
    }, [authSettings]);

    const logout = useCallback(
        async () => {
            await RequestExecutor.call(RequestEndpoint.logout, ERequestMethod.POST)
                .finally(() => {
                    window.localStorage.removeItem("Authorization");
                    window.localStorage.removeItem("refresh_token");
                    redirectToSso();
                });
        },
        [redirectToSso]
    );

    const refreshToken = useCallback(
        async () => {
            const refreshToken = tokenDto?.refreshToken || window.localStorage.getItem("refresh_token");
            await RequestExecutor.callNotAuth(RequestEndpoint.getRefreshToken, ERequestMethod.GET, {},{refreshToken: refreshToken})
                .then((newTokenDto) => {
                    setTokenDto(newTokenDto);
                    window.localStorage.setItem("Authorization", newTokenDto.accessToken);
                    window.localStorage.setItem("refresh_token", newTokenDto.refreshToken);
                })
                .catch(() => logout());
        },
        [tokenDto, logout]
    );

    const callWithTokenCheck = useCallback(
        async <K extends RequestEndpoint, RESP extends IResponseBody = RequestInfoTypeMapping[K]> (
            endpoint: K,
            method: ERequestMethod,
            requestBody: object = {},
            queryParam: object = {},
            options: Partial<RequestOptions> = {},
        ): Promise<RESP> => {
            return await RequestExecutor.call(endpoint, method, requestBody, queryParam, options)
                .catch(async (error) => {
                    console.log(error);
                    if (error?.error === "ExpiredTokenAuthError") {
                        await refreshToken();
                    } else {
                        return Promise.reject(error);
                    }
                    return await RequestExecutor.call(endpoint, method, requestBody, queryParam, options);
                }) as Promise<RESP>;
        }, [refreshToken]
    );

    useEffectAsync(async () => {
        const config = await RequestExecutor.call(RequestEndpoint.getAuthSettings, ERequestMethod.GET);
        setAuthSettings(config);
        //TODO отлов ошибки и не давать пользоваться приложением

        setInitLoading(false);
    }, []);

    const navigate = useNavigate();
    useEffectAsync(async () => {
        if (initLoading) {
            return;
        }

        const isHrefIncludeToken = window.location.href.includes("token");
        const isHrefIncludeError = window.location.href.includes("error"); //есть ошибка и чего?
        if (isHrefIncludeToken) {
            const token = getQueryParam("token");
            window.localStorage.setItem("Authorization", token!!);
            navigate("/");
        }
        const token = window.localStorage.getItem("Authorization");
        if (!!token && !tokenDto) {
            await callWithTokenCheck(RequestEndpoint.getTokenDto, ERequestMethod.GET)
                .then((loadedTokenDto) => {
                    setTokenDto(loadedTokenDto);
                    window.localStorage.setItem("refresh_token", loadedTokenDto.refreshToken);
                })
                .catch(() => logout());
        }
        if (!isHrefIncludeToken && !token && !isHrefIncludeError) {
            redirectToSsoAuth();
        }
    }, [initLoading]);

    return (
        <SessionContext.Provider value={{tokenDto, callWithTokenCheck, logout}}>
            {children}
        </SessionContext.Provider>
    )
};

export default SessionProvider;